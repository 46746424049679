import { BigNumber } from 'bignumber.js';
import {
	BakingRightsQueryArguments,
	BakingRightsResponse,
	BalanceResponse,
	BallotListResponse,
	BallotsResponse,
	BigMapGetResponse,
	BigMapKey,
	BigMapResponse,
	BlockHeaderResponse,
	BlockMetadata,
	BlockResponse,
	ConstantsResponse,
	ContractResponse,
	CurrentProposalResponse,
	CurrentQuorumResponse,
	DelegateResponse,
	DelegatesResponse,
	EndorsingRightsQueryArguments,
	EndorsingRightsResponse,
	EntrypointsResponse,
	ForgeOperationsParams,
	ManagerKeyResponse,
	OperationHash,
	PackDataParams,
	PeriodKindResponse,
	PreapplyParams,
	PreapplyResponse,
	ProposalsResponse,
	RPCRunCodeParam,
	RPCRunOperationParam,
	RunCodeResult,
	SaplingDiffResponse,
	ScriptResponse,
	StorageResponse,
	VotesListingsResponse,
	VotingPeriodBlockResult
} from './types';

export interface RPCOptions {
	block: string;
}

export const defaultChain = 'main';
export const defaultRPCOptions: RPCOptions = { block: 'head' };

export interface RpcClientInterface {
	getBlockHash(options?: RPCOptions): Promise<string>;
	getLiveBlocks(options?: RPCOptions): Promise<string[]>;
	getBalance(address: string, options?: RPCOptions): Promise<BalanceResponse>;
	getStorage(address: string, options?: RPCOptions): Promise<StorageResponse>;
	getScript(address: string, options?: RPCOptions): Promise<ScriptResponse>;
	getContract(address: string, options?: RPCOptions): Promise<ContractResponse>;
	getManagerKey(address: string, options?: RPCOptions): Promise<ManagerKeyResponse>;
	getDelegate(address: string, options?: RPCOptions): Promise<DelegateResponse>;
	getBigMapKey(address: string, key: BigMapKey, options?: RPCOptions): Promise<BigMapGetResponse>;
	getBigMapExpr(id: string, expr: string, options?: RPCOptions): Promise<BigMapResponse>;
	getDelegates(address: string, options?: RPCOptions): Promise<DelegatesResponse>;
	getConstants(options?: RPCOptions): Promise<ConstantsResponse>;
	getBlock(options?: RPCOptions): Promise<BlockResponse>;
	getBlockHeader(options?: RPCOptions): Promise<BlockHeaderResponse>;
	getBlockMetadata(options?: RPCOptions): Promise<BlockMetadata>;
	getBakingRights(args: BakingRightsQueryArguments, options?: RPCOptions): Promise<BakingRightsResponse>;
	getEndorsingRights(args: EndorsingRightsQueryArguments, options?: RPCOptions): Promise<EndorsingRightsResponse>;
	getBallotList(options?: RPCOptions): Promise<BallotListResponse>;
	getBallots(options?: RPCOptions): Promise<BallotsResponse>;
	getCurrentPeriodKind(options?: RPCOptions): Promise<PeriodKindResponse>;
	getCurrentProposal(options?: RPCOptions): Promise<CurrentProposalResponse>;
	getCurrentQuorum(options?: RPCOptions): Promise<CurrentQuorumResponse>;
	getVotesListings(options?: RPCOptions): Promise<VotesListingsResponse>;
	getProposals(options?: RPCOptions): Promise<ProposalsResponse>;
	forgeOperations(data: ForgeOperationsParams, options?: RPCOptions): Promise<string>;
	injectOperation(signedOpBytes: string): Promise<OperationHash>;
	preapplyOperations(ops: PreapplyParams, options?: RPCOptions): Promise<PreapplyResponse[]>;
	getEntrypoints(contract: string, options?: RPCOptions): Promise<EntrypointsResponse>;
	runOperation(op: RPCRunOperationParam, options?: RPCOptions): Promise<PreapplyResponse>;
	runCode(code: RPCRunCodeParam, options?: RPCOptions): Promise<RunCodeResult>;
	getChainId(): Promise<string>;
	packData(data: PackDataParams,options?: RPCOptions): Promise<{ packed: string; gas: BigNumber | 'unaccounted' | undefined }>;
	getRpcUrl(): string;
	getCurrentPeriod(options?: RPCOptions): Promise<VotingPeriodBlockResult>;
	getSuccessorPeriod(options?: RPCOptions): Promise<VotingPeriodBlockResult>;
	getSaplingDiffById(id: string, options?: RPCOptions): Promise<SaplingDiffResponse>;
	getSaplingDiffByContract(contract: string, options?: RPCOptions): Promise<SaplingDiffResponse>;
}
